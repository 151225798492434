import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 'auto', 
            [theme.breakpoints.down('md')]: {
                width: 272,
            },
            [theme.breakpoints.up('md')]: {
                width: 1040,
            }
        },
        headerContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 16,
            marginTop: 24,
        },
        header: {
            ...theme.typography.txtBody2,
            color: theme.colors.grey6,
            fontSize: 14,
            fontWeight: 700,
        },
        servicesContainer: {
            display: 'flex',
            gap: 13,
            overflow: 'hidden',

            [theme.breakpoints.down('md')]: {
                height: 276,
                width: 226,
                marginLeft: 5,
                marginRight: 5,
            },
            [theme.breakpoints.up('md')]: {
                height: 277,
                width: 1040,
                marginLeft: 7,
                marginRight: 7,
            },
        },
        navigationContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            [theme.breakpoints.down('md')]: {
                marginLeft: 0,
                marginRight: 0,
                marginBottom: 0,
            },
            [theme.breakpoints.up('md')]: {
                marginLeft: -31,
                marginRight: -31,
                marginBottom: 150,
            },
        },
        arrow: {
            cursor: 'pointer',
            [theme.breakpoints.down('md')]: {
                margin: -6
            }
        },
        partialCard: {
            width: '233px !important',
        }
    })
);

export default useStyles;
