import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { schedulerActions } from "../../../redux/scheduler-slice";

import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { DeleteIcon, PlusIcon } from "./../../../assets/icons";
import { ServiceUserModel } from "../../../models/service/service-user.model";
import { format } from 'date-fns';
import useStyles from "./css";
import ConfirmDeleteModal from "../../common/ConfirmDeleteModal";
import { ServiceItem } from "../../../models/service/service-item.model";
import { ScheduleTime } from "../../../models/schedule";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import PaperIcon from "../../../assets/icons/PaperIcon";
import ExclamationModal from "../../common/ExclamationModal";
import { Button } from "@material-ui/core";
import InformationModal from "../../common/InformationModal/information-modal";
import CancellationPoliciesBlock from "../../account/CancellationPoliciesBlock";


export default function ScheduleAppointmentStepTwo() {
  const { t } = useTranslation(["general"]);
  const theme = useTheme();
  const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  const dispatch = useAppDispatch();
  
  const serviceUsers = useAppSelector((state) => state.scheduler.serviceUser);
  const refreshAvailableHours = useAppSelector((state) => state.scheduler.refreshAvailableHours);
  const scheduleTime = useAppSelector((state) => state.scheduler.scheduleTime);
  const schedule = useAppSelector((state) => state.scheduler.schedule);
  const isUpdate = useAppSelector((state) => state.scheduler.isUpdate);
  const date = useAppSelector((state) => state.scheduler.currentDate);
  
  const localeApp = useAppSelector(state => state.scheduler.locale);
  const account = useAppSelector((state) => state.scheduler.account);
  
  const isEditable = useAppSelector((state) => state.scheduler.isEditable);
  const isServicesPerformedSimultaneously = useAppSelector((state) => state.scheduler.isServicesPerformedSimultaneously);
  
  const {
    setServiceUser,
    setIsValidSelection,
    setIsValidUser,
    setScheduleTime,
    setRefreshAvailableHours,
    setAvailableHours,
  } = schedulerActions;
  
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openCancellationPoliciesDialog, setOpenCancellationPoliciesDialog] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<ServiceItem | null>(null);
  
  function handleAskToRemoveService(e: any, model: ServiceUserModel) {
    e.preventDefault();
    e.stopPropagation();
    
    const s = { key: model.service?.id, value: model.service?.name, index: model.index } as ServiceItem;
    setItemToDelete(s);
    setOpenDeleteDialog(true);
  }
  
  async function handleOnDelete(item: ServiceUserModel) {
    const arrServices = serviceUsers.filter((data: ServiceUserModel) => data.index !== item.index);
    const updatedServices = arrServices.map((data: ServiceUserModel, index: number) => ({
      ...data,
      index: index
    }));
    dispatch(setServiceUser([...updatedServices]));
    dispatch(setScheduleTime(undefined));
    handleValidChange([...updatedServices]);
    setItemToDelete(null);
    setOpenDeleteDialog(false);
  }
  
  function handleValidChange(su?: ServiceUserModel[]) {
    let servicesList = su ?? serviceUsers;
    let isValidLocal = true;
    for (let i of servicesList) {
      const s = i.serviceId;
      
      if (s === null || s === undefined || s === "") { //  && s !== "All"
        isValidLocal = false;
        break;
      }
    }
    
    dispatch(setIsValidSelection(isValidLocal));
    isValidLocal = true;
    for (let item of servicesList) {
      if (item.serviceId && item.users !== null && item.users.length && !item.userId) {
        isValidLocal = false;
        break;
      }
    }
    
    dispatch(setIsValidUser(isValidLocal));
    dispatch(setAvailableHours([new ScheduleTime()]));
    dispatch(setRefreshAvailableHours(refreshAvailableHours + 1));
  }
  
  function getSpecialistName(serviceUser: ServiceUserModel) {
    if (serviceUser.userId === 'All') {
      return t("Any specialist");
    }
    let specialist = serviceUser.users?.find(user => user.id == serviceUser.userId);
    return `${specialist?.firstName} ${specialist?.lastName}`;
  }
  
  function getAppointmentDate(): string {
    let dateFormat = localeApp == 'es'
      ? "dd 'de' MMMM yyyy"
      : "MMMM dd, yyyy";
    return format(date, dateFormat, { locale: getLocale() });
  }
  
  function getAppointmentTime(serviceUser: ServiceUserModel): string {
    let startAppointmentTime = scheduleTime?.scheduleTime?.split(" - ")[0];
    let duration = serviceUser.service?.duration;
    let index = serviceUser.index;
    
    if (isServicesPerformedSimultaneously) {
      return scheduleTime?.scheduleTime!;
    }
    
    if (duration && startAppointmentTime) {
      let durationSum = serviceUsers.slice(0, index)
        .map((user: ServiceUserModel) => user.service?.duration || 0)
        .reduce((total: number, d: number) => total + (d || 0), 0);
      
      let startTime = addMinutes(startAppointmentTime, durationSum);
      
      let endTime = addMinutes(startTime, duration);
      
      return `${startTime} - ${endTime}`;
    }
    
    return "\u00A0";
  }
  
  function addMinutes(time: string, minutes: number): string {
    let [hours, mins] = time.split(":").map(Number);
    let date = new Date();
    date.setHours(hours);
    date.setMinutes(mins + minutes);
    return `${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
  }
  
  function getLocale(): any {
    switch (localeApp) {
      case "es":
        return esLocale;
      
      default:
        return enLocale;
    }
  }
  
  const DivInfoDelete: React.FC = () => {
    return (<>
      <div className={classes.modalCenter} style={{ marginTop: 20 }}>
        <Typography className={classes.modalContentText}>
          {t("Are you sure you want to delete this service?")}
        </Typography>
        <Typography className={classes.modalContentTextServiceName}>
          {itemToDelete && itemToDelete.value ? itemToDelete.value : "- -"}
        </Typography>
      </div>
      <div className={classes.subtitle}>
        <hr style={{ width: 250 }}/>
        {t("You'll need to go back to the previous step and choose a different time to schedule your appointment.")}
      </div>
    </>);
  };

  const CancellationInfo = () =>
    <div className={classes.normalText}>
      <CancellationPoliciesBlock text={account.cancellationPolicies!} />
    </div>
  
  return (<div className={classes.step2Root}>
    <div className={classes.totalServicesText}>
      {t("You have added")} {serviceUsers.length} {t("Services").toLowerCase()}
    </div>
    {serviceUsers && serviceUsers.map((serviceUser: ServiceUserModel, index: number) => (
      <div key={index} className={classes.serviceContainer}>
        <div className={classes.imageContainer}>
          {serviceUser.service?.photo
            ? <img src={serviceUser.service?.photo ?? ""} alt="Service Photo" className={classes.servicePhoto}/>
            : ""}
        </div>
        
        <div className={classes.serviceInfoContainer} style={{ width: 158 }}>
           <span className={classes.serviceInfoHeader}>
             {t("SERVICE")}
           </span>
          <span className={classes.serviceInfo}>
             {`${serviceUser.service?.categoryName} | ${serviceUser.service?.name}` }
           </span>
        </div>
        <div className={classes.serviceInfoContainer} style={{ width: 120 }}>
           <span className={classes.serviceInfoHeader}>
             {t("SPECIALIST")}
           </span>
          <span className={classes.serviceInfo}>
               {getSpecialistName(serviceUser)}
           </span>
        </div>
        <div className={classes.serviceInfoContainer} style={{ width: 87 }}>
           <span className={classes.serviceInfoHeader}>
             {t("DURATION")}
           </span>
          <span className={classes.serviceInfo}>
             {serviceUser.service?.duration} {t("minutes")}
           </span>
        </div>
        <div className={classes.serviceInfoContainer} style={{ width: 141 }}>
           <span className={classes.serviceInfoHeader}>
             {t("DATE")}
           </span>
          <span className={classes.serviceInfo}>
             {getAppointmentDate()}
           </span>
        </div>
        <div className={classes.serviceInfoContainer} style={{ width: 94 }}>
           <span className={classes.serviceInfoHeader}>
             {t("TIME")}
           </span>
          <span className={classes.serviceInfo}>
             {getAppointmentTime(serviceUser)}
           </span>
        </div>
        <div className={classes.serviceInfoContainer} style={{ width: 72 }}>
          {account.showPrices && <>
            <span className={classes.serviceInfoHeader}>
             {t("PRICE")}
           </span>
            <span className={classes.serviceInfo}>
             $ {serviceUser.service?.price}
           </span>
          </>}
        </div>
        <div className={classes.deleteDiv}>
          {serviceUsers.length > 1 && isEditable
            ? <div className={classes.deleteButton} onClick={(e) => handleAskToRemoveService(e, serviceUser)}>
              <DeleteIcon/> {isMdMedia ? "" : t("Delete service")}
            </div>
            : null
          }
        </div>
      </div>
    ))}
    
    
    <div className={classes.footerContainer}>
      <div className={classes.total}>
        {account.showPrices && <>
          <span className={classes.totalSpan}> {t("Total ")} </span>
          <span className={classes.totalValueSpan}>
        ${serviceUsers.reduce((sum: number, serviceUser: ServiceUserModel) => {
            return sum + (serviceUser.service?.price || 0);
          }, 0)}
      </span>
        </>}
      </div>
      <div className={classes.cancellationContainer}>
        {account?.showCancellationPolicies && account?.cancellationPolicies && account.cancellationPolicies.length > 1 && account.cancellationPolicies.trim() !== "" &&
          <>
            {isMdMedia &&
              <>
                <div className={classes.iconContainer}>
                  <PaperIcon height={10} color={"#5C6477"} className={classes.icon}/>
                  <Typography className={classes.cancellationText}>
                    {t("Cancellation policies")}
                  </Typography>
                </div>
                <div className={classes.cancellationTextContainer}>
                  <div className={classes.cancellationText} style={{ marginTop: 8 }}>
                    <CancellationPoliciesBlock text={account.cancellationPolicies} />
                  </div>
                </div>
              </>
            }
            {!isMdMedia &&
              <div className={classes.cancellationTextContainer}>
                <>
                  <Button onClick={() => setOpenCancellationPoliciesDialog(true)}>
                    <PaperIcon height={10} color={"#5C6477"} className={classes.icon}/>
                    {t("Cancellation policies")}
                  </Button>
                  <div
                    className={classes.separator}
                  />
                </>
              </div>
            }
          </>}
      </div>
    </div>
    
    <ConfirmDeleteModal
      width={isMdMedia ? 470 : 328}
      textButtonConfirm={t("Delete service")}
      open={openDeleteDialog}
      item={itemToDelete}
      componentInfo={<DivInfoDelete/>}
      onClose={() => setOpenDeleteDialog(false)}
      onDelete={handleOnDelete}
    />
    <InformationModal
      open={openCancellationPoliciesDialog}
      title={<><PaperIcon height={14} color={"#5C6477"} className={classes.iconModal}/> {t("Cancellation policies")}</>}
      body={<CancellationInfo />}
      okTextButton={t("OkUnderstand")}
      onClose={() => setOpenCancellationPoliciesDialog(false)}
      scrollable
    />
  </div>);
};
