import React, { useEffect, useState } from "react";

import { Tooltip, ClickAwayListener } from "@material-ui/core";

import Input from "../Input";
import { SelectTimeProps } from "./props";
import useStyles from "./css";
import ArrowDownIcon from "../../../assets/icons/ArrowDownIcon";
import TimeIcon from "../../../assets/icons/TimeIcon";


const SelectTime = (props: SelectTimeProps) => {
    const classes = useStyles();
    const {
        items,
        itemSelected,
        placeholderSelect,
        isValid,
        disabled,
        onSelect
    } = props;



    const [openDropdown, setOpenDropdown] = useState(false);
    const showToolTip = (!openDropdown && !disabled && itemSelected);

    const handleItemSelected = (selectedItem: string) => {
        onSelect(selectedItem);
        setOpenDropdown(false);
    };


    const handleOpenDropdown = () => !disabled && setOpenDropdown(!openDropdown);

    const handleCloseDropdown = () => setOpenDropdown(false);

    const InputWithIcons = (
        <div onClick={handleOpenDropdown} style={{ cursor: disabled ? 'default' : 'pointer' }}>
            <Input
                value={itemSelected}
                placeholder={placeholderSelect}
                inputClass={classes.inputSelect}
                isValid={() => isValid}
            />
            <div className={`${classes.iconDropdown} ${classes.timeIconContainer}`}>
                <TimeIcon className={classes.timeIcon} />
            </div>
            <div className={`${classes.iconDropdown} ${classes.arrowIconContainer} ${openDropdown ? classes.arrowIconRotated : ''}`}>
                <ArrowDownIcon className={classes.arrowIcon} />
            </div>
        </div>
    );

    return (
        <ClickAwayListener onClickAway={handleCloseDropdown}>
            <div className={classes.root}>
                <div className={classes.divInput}>
                    {showToolTip
                      ? (
                        <Tooltip
                            placement="bottom"
                            classes={{ popper: classes.popper }}
                            title={itemSelected ?? {}}
                            style={{ maxHeight: 40 }}
                        >
                            <div>
                                {InputWithIcons}
                            </div>
                        </Tooltip>)
                      : (
                         <div>
                             {InputWithIcons}
                         </div>)
                    }
                </div>
                {openDropdown && (
                    <div className={classes.listDropdownContainer}>
                    <div className={classes.listDropdown}>
                        {items.map((i) => (
                            <div
                                key={i}
                                onClick={() => handleItemSelected(i)}
                                className={`${classes.listItem} ${itemSelected === i ? classes.itemSelected : classes.itemNoSelected}`}>
                               <div>
                                 {i}
                               </div>
                            </div>
                        ))}
                    </div>
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
};

export default SelectTime;
