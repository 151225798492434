import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.txtBody2,
    borderRadius: 6,
    background: "#FFF",
    border: `1px solid ${theme.colors.grey2}`,
    height: 46,
    backgroundColor: "#FFF",
    boxShadow: "2px 2px 12px 5px #EBEBEB",
    paddingLeft: 16,
    "&:focus": {
      border: "solid 1px #6462F3",
    },
  },
  rootNoBorder: {
    ...theme.typography.txtBody2,
    borderRadius: "100px",
    background: "#F6F6F6",
    height: 46,
    paddingLeft: 16,
  },
  autocomplete: {
    height: 46,
  },
  inputFocused: {
    border: "solid 1px #6462F3",
  },
}));

export default useStyles;
