export class UserModel {
    id: string | null;
    firstName: string | null;
    lastName: string | null;
  
    constructor(x?: any) {
      if (typeof x === "object") {
        this.id = x.id;
        this.firstName = x.firstName;
        this.lastName = x.lastName;
      } else {
        this.id = null;
        this.firstName = null;
        this.lastName = null;
      }
    }
  }
  