import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    step3Root: {
      width: 817, 
      paddingRight: 10, 
      marginTop: 15,
      marginBottom: 15,
      [theme.breakpoints.down("sm")]: {
        width: 270,
        paddingRight: 0,
        height: 440
      },
    },
    modalTitle: {
      ...theme.typography.txtBody1,
      fontWeight: "bold",
      color: theme.colors.grey5,
    },
    button: {
      height: 32,
      width: "100%",
      borderRadius: 51,
      ...theme.typography.buttons,
      fontWeight: "bold",
    },
    goBackButton: {
      color: theme.colors.grey3,
      border: `1px solid ${theme.colors.grey3}`,
    },
    newAppointmentButton: {
      height: 32,
      color: theme.colors.grey1,
      background: theme.colors.clearGreen,
      "&:hover": {
        background: theme.colors.clearGreen,
      },
      "&.Mui-disabled": {
        opacity: 0.3,
        color: theme.colors.grey1,
      },
    },
    requiredLabel: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey4,
    },
    label: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey4,
      fontWeight: "bold",
      display: "block",
    },
    input: {
      backgroundColor: theme.colors.grey1,
      height: 40,
      padding: "13px 0px 13px 12px",
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 5,
      width: "100%",
    },
    inputTextareaAutosize: {
      ...theme.typography.txtBody2,
      backgroundColor: theme.colors.grey1,
      height: 40,
      padding: "13px 0px 13px 12px",
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 5,
      width: "98%",
      "&:focus": {
        border: `solid 1px ${theme.colors.clearMain} !important`,
      },
      "&::placeholder": {
        ...theme.typography.txtBody2,
      },
      [theme.breakpoints.down("sm")]: {
        width: 256
      },
    },
    inputText: {
      ...theme.typography.txtBody2,
      fontWeight: "bold",
      color: theme.colors.grey5,
      "&::placeholder": {
        fontWeight: "normal",
        color: "#676767",
      },
    },
    topInputsContainer: {
      display: "flex",
      marginBottom: 8,
      gap: 27,
      paddingRight: 5,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        paddingRight: 0,
        gap: 16
      },
    },
    topInputsContainerItem: {
      flex: 1
    },
    inputFocused: {
      border: `solid 1px ${theme.colors.clearMain}`,
      padding: "14px 0px 14px 12px",
      width: "100%",
    },
    inputDisabled: {
      color: "#676767",
    },
    inputError: {
      color: theme.colors.clearRed,
      border: `solid 1px ${theme.colors.clearRed}`,
    },
    inputMask: {
      backgroundColor: theme.colors.grey1,
      ...theme.typography.txtBody2,
      fontWeight: "bold",
      color: theme.colors.grey5,
      "&::placeholder": {
        fontWeight: "normal",
        color: "#676767",
        opacity: "0.42",
      },
      height: 40,
      padding: "13px 0px 13px 12px",
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 5,
      width: "100%",
      "&$inputError": {
        color: theme.colors.clearRed,
        border: `solid 1px ${theme.colors.clearRed}`,
      },
      "&:focus": {
        border: `solid 1px ${theme.colors.clearMain}`,
        padding: "14px 0px 14px 12px",
        width: "100%",
        "&$inputError": {
          color: theme.colors.clearRed,
          border: `solid 1px ${theme.colors.clearRed}`,
        },
      },
      "&:focus:not(:focus-visible)": {
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
        border: `solid 1px ${theme.colors.clearMain}`,
        padding: "14px 0px 14px 12px",
        width: "100%",
        "&$inputError": {
          color: theme.colors.clearRed,
          border: `solid 1px ${theme.colors.clearRed}`,
        },
      },
    },
  })
);

export default useStyles;
