import React from "react";

import { useHistory } from "react-router-dom";

import { useTranslation, Trans } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { schedulerActions } from "../../../redux/scheduler-slice";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import AppointmentSummary from "./../AppointmentSummary";
import { ClearBrandIcon } from "./../../../assets/icons";
import { ScheduleAppointment } from "../../../models/schedule";
import useStyles from "./css";
import { ServiceUserModel } from "../../../models/service/service-user.model";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CancelAppointmentButton from "../CancelAppointmentButton";
import { ReactComponent as CanNotSchedule } from "../../../assets/images/can-not-schedule.svg";


export default function ScheduleAppointmentFailedStep() {
  
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  
  const theme = useTheme();
  const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));
  
  const account = useAppSelector((state) => state.scheduler.account);
  
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {/* Desktop Begin */}
        <div className={classes.leftSideImage}/>
        <div className={classes.appntInfoContainer}>
          <div className={classes.rowCenter} style={{ marginTop: 16 }}>
            <CanNotSchedule />
          </div>
          <div className={classes.dividerTop}/>
          <div className={classes.rowCenter} style={{ marginTop: 16 }}>
            <Typography className={classes.brandingTitle}>
              {t("Oops! We can't schedule your appointment")}
            </Typography>
          </div>
          <div className={classes.rowCenter} style={{ marginTop: 16 }}>
            <Typography className={classes.text}>
              {t("At the moment, we do not have availability.")}
            </Typography>
          </div>
          <div className={classes.rowCenter}>
            <Typography className={classes.text}>
              {t("We appreciate your patience.")}
            </Typography>
          </div>
        </div>
        {/* Desktop End */}
      </div>
    </div>
  );
}