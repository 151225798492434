import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const PaperIcon = (props: any) => {
  const { style, height, color, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 14, height: height ?? 16, color: color ?? "#919CA5", ...style }}
      viewBox={viewBox || "0 0 14 16"}
    >
      <path d="M12.0005 15.9989H1.9985C1.48653 16.0152 0.988696 15.8371 0.614085 15.5036C0.239474 15.1702 0.0186472 14.7085 0 14.22L0 1.77804C0.0189162 1.28971 0.239897 0.828452 0.61451 0.495361C0.989123 0.162269 1.48681 -0.0154835 1.9985 0.0010594H8.24643V4.55496C8.26233 4.95851 8.44497 5.33964 8.75441 5.61506C9.06385 5.89047 9.47494 6.03776 9.89782 6.02474H14V14.2189C13.9816 14.7079 13.7608 15.1699 13.3859 15.5036C13.011 15.8373 12.5128 16.0155 12.0005 15.9989ZM3.78663 11.7221C3.73487 11.7195 3.6831 11.727 3.63447 11.7441C3.58585 11.7613 3.54138 11.7876 3.50379 11.8217C3.46619 11.8557 3.43625 11.8967 3.41579 11.9422C3.39533 11.9876 3.38477 12.0366 3.38477 12.0861C3.38477 12.1355 3.39533 12.1845 3.41579 12.2299C3.43625 12.2754 3.46619 12.3164 3.50379 12.3504C3.54138 12.3845 3.58585 12.4109 3.63447 12.428C3.6831 12.4451 3.73487 12.4526 3.78663 12.45H10.3859C10.4376 12.4526 10.4894 12.4451 10.538 12.428C10.5867 12.4109 10.6311 12.3845 10.6687 12.3504C10.7063 12.3164 10.7363 12.2754 10.7567 12.2299C10.7772 12.1845 10.7877 12.1355 10.7877 12.0861C10.7877 12.0366 10.7772 11.9876 10.7567 11.9422C10.7363 11.8967 10.7063 11.8557 10.6687 11.8217C10.6311 11.7876 10.5867 11.7613 10.538 11.7441C10.4894 11.727 10.4376 11.7195 10.3859 11.7221H3.78663ZM3.78663 9.53855C3.68536 9.53855 3.58824 9.57694 3.51664 9.64529C3.44504 9.71363 3.40481 9.80633 3.40481 9.90298C3.40481 9.99963 3.44504 10.0923 3.51664 10.1607C3.58824 10.229 3.68536 10.2674 3.78663 10.2674H10.3859C10.4871 10.2674 10.5843 10.229 10.6559 10.1607C10.7275 10.0923 10.7677 9.99963 10.7677 9.90298C10.7677 9.80633 10.7275 9.71363 10.6559 9.64529C10.5843 9.57694 10.4871 9.53855 10.3859 9.53855H3.78663ZM13.3868 5.29085H9.89782C9.79336 5.29515 9.68906 5.27954 9.591 5.24492C9.49294 5.2103 9.40309 5.15737 9.3267 5.08923C9.25031 5.02109 9.18892 4.93911 9.14612 4.84806C9.10331 4.75701 9.07994 4.65874 9.07738 4.55898V0.792169L13.3836 5.28583L13.3868 5.29085Z" />
    </SvgIcon>
  );
};

export default PaperIcon;
