import React, { useEffect, useState } from 'react';

import { Link, useParams, useRouteMatch } from "react-router-dom";

import { Tab, Tabs } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { Url } from "../../../constants";
import useStyles from "./css";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function NavTabs(props: any) {
  
  const routeMatch = useRouteMatch({
    path: [
      Url.Navbar.ScheduleAppointment,
      Url.Navbar.AboutUs,
      Url.Navbar.Services
    ],
    exact: true
  });
  
  const currentTab = routeMatch?.path;
  
  const { t } = useTranslation(["general"]);
  
  const { accountBusinessName } = useParams<any>();
  
  const theme = useTheme();
  const isMdMedia = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  
  const classes = useStyles();
  
  return (
    <>
          <Tabs
            value={currentTab}
            indicatorColor="primary"
            orientation={isMdMedia ? "horizontal" : "vertical"}
            textColor="primary"
            classes={{root: classes.tabsRoot}}
            className={classes.tabs}
          >
            <Tab
              className={classes.tab}
              label={t("Schedule appointment")}
              value={Url.Navbar.ScheduleAppointment}
              to={`/${accountBusinessName}/appointment`}
              component={Link}/>
            <Tab
              className={classes.tab}
              label={t("About us")}
              value={Url.Navbar.AboutUs}
              to={`/${accountBusinessName}/about`}
              component={Link}/>
            <Tab
              className={classes.tab}
              label={t("Services")}
              value={Url.Navbar.Services}
              to={`/${accountBusinessName}/services`}
              component={Link}/>
          </Tabs>
    </>);
}