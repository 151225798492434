import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MoneyIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 10, height: 17, color: "#919CA5", ...style }}
      viewBox={viewBox || "0 0 10 17"}
    >
      <path d="M6.28763 15.91V15.1098C8.44482 14.739 9.99443 13.1581 10 11.3272C10 10.2128 9.38499 8.22833 5.28428 7.45392C2.58021 6.93766 2.58021 5.98633 2.58021 5.67216C2.58021 4.46712 3.83686 3.91622 5 3.91622C5.4474 3.89729 5.89147 4.0022 6.2848 4.21975C6.67814 4.4373 7.00599 4.75934 7.23337 5.15149C7.33465 5.34403 7.4822 5.5074 7.66228 5.62639C7.84237 5.74539 8.04913 5.81615 8.26335 5.83208C8.48396 5.86205 8.7084 5.83948 8.91896 5.76616C9.12952 5.69285 9.32044 5.57079 9.47665 5.40962C9.61244 5.26786 9.6995 5.08526 9.72484 4.88908C9.75018 4.69289 9.71244 4.49364 9.61724 4.32105C9.27059 3.69664 8.79306 3.1574 8.2185 2.74155C7.64395 2.3257 6.98644 2.04342 6.29258 1.91473V1.07548C6.2324 0.772073 6.07079 0.499189 5.83518 0.30315C5.59956 0.107111 5.30447 0 5 0C4.69553 0 4.40044 0.107111 4.16482 0.30315C3.92921 0.499189 3.7676 0.772073 3.70742 1.07548V1.8757C1.55209 2.24842 0.00557414 3.82745 0 5.65831C0 6.7727 0.610057 8.75719 4.71572 9.53159C7.42537 10.0479 7.42537 11.0036 7.42537 11.3134C7.42537 12.5184 6.16871 13.0693 5.00557 13.0693C4.55679 13.0898 4.11096 12.9859 3.71581 12.7686C3.32066 12.5513 2.99107 12.2289 2.76229 11.8359C2.58415 11.5571 2.31708 11.3492 2.00593 11.2469C1.69477 11.1446 1.35848 11.1543 1.05351 11.2743C0.908776 11.3093 0.773531 11.3766 0.657618 11.4714C0.541706 11.5662 0.448041 11.6861 0.383438 11.8223C0.318835 11.9586 0.284918 12.1077 0.284158 12.259C0.283397 12.4102 0.315812 12.5597 0.379041 12.6966C0.725761 13.3209 1.20332 13.86 1.77787 14.2757C2.35243 14.6915 3.0099 14.9736 3.7037 15.1023V15.9245C3.76388 16.2279 3.9255 16.5008 4.16111 16.6968C4.39672 16.8929 4.69181 17 4.99628 17C5.30076 17 5.59585 16.8929 5.83146 16.6968C6.06707 16.5008 6.22868 16.2279 6.28886 15.9245L6.28763 15.91Z" />
    </SvgIcon>
  );
};

export default MoneyIcon;
