import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@material-ui/core';

import useStyles from './css';
import { SuggestedServiceCardProps } from './props';
import { PlusIcon } from '../../../assets/icons';
import Tooltip from "@material-ui/core/Tooltip";


export default function SuggestedServiceCard(props: SuggestedServiceCardProps) {
    const classes = useStyles();

    const { t } = useTranslation(["general"]);

    const { service, handleAddService, showPrices, classCard } = props;

    const formatPrice = (price: number) => {
        const decimalPart = price.toString().split('.')[1] || '';

        switch (decimalPart.length) {
            case 0:
                return `$${price}.00`;
            case 1:
                return `$${price}0`;
            case 2:
                return `$${price}`;
        }
    };

    return (
        <div className={`${classCard} ${classes.serviceCard}`}>
            <div className={classes.serviceImageContainer}>
                {service.photo &&
                    <img
                        className={classes.serviceImage}
                        src={service.photo}
                    />
                }
            </div>
            <div className={classes.serviceInfo}>
                <div className={classes.serviceNamePriceContainer}>
                    <Tooltip
                        title={`${service.categoryName} | ${service.name}`}
                        className={classes.tooltip}
                    >
                        <Typography className={classes.serviceName}>
                            {`${service.categoryName} | ${service.name}`}
                        </Typography>
                    </Tooltip>
                    <Typography className={classes.servicePrice}>
                        {showPrices && `${formatPrice(service.price!)} |`} {service.duration} {t("minutes")}
                    </Typography>
                </div>
                <div className={classes.serviceDescriptionContainer}>
                    <Tooltip
                        title={<span>{service.description}</span>}
                        className={classes.tooltip}
                    >
                        <Typography className={classes.serviceDescription}>
                            {service.description}
                        </Typography>
                    </Tooltip>
                </div>
                <div className={classes.addButtonContainer}>
                    <Button className={classes.addButton}
                        onClick={() => handleAddService(service)}
                        disabled={false}>
                        <div className={classes.addButtonItems}>
                            <PlusIcon />
                            {t("Add Service")}
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
};
