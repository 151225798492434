import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
        minHeight: "95vh",
        backgroundColor: "#FAFAFA"
      },
    },
    content: {
      paddingTop: 15,
    },
    buttonsFlexContainer: {
      display: "flex",
      gap: 16,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        width: "272px"
      },
    },
    paper: {
      width: "100%",
    },
    leftPanel: {
      background: theme.colors.grey7,
      padding: "23px 19px 0px 19px",
    },
    branding: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    brandingCircle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      
      height: 80,
      width: 80,
      borderRadius: "50%",
    },
    imageCircle: {
      height: 80,
      width: 80,
      borderRadius: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    brandTitle: {
      ...theme.typography.header4,
      fontWeight: "bold",
      color: theme.colors.grey1,
      marginTop: 16,
      marginBottom: 24,
      display: "flex",
      justifyContent: "center",
    },
    leftMain: {
      height: "calc(100% - 165px)",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      scrollbarWidth: "none",
      "-ms-overflow-style": "none",
    },
    modifyCaption: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 21,
      display: "grid",
      marginRight: 10,
      marginTop: 15,
      color: theme.colors.clearMain,
      [theme.breakpoints.down("sm")]: {
        width: "272px!important",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 0
      },
      "&::after": {
        content: "''",
        borderBottom: "1px solid #ACB7C0",
        margin: "16px 0 5px 0",
        [theme.breakpoints.down("sm")]: {
          margin: "16px 0 18px 0",
        },
      },
    },
    modifyCaptionContainer: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        backgroundColor: theme.colors.white,
      },
    },
    rightPanel: {
      padding: "24px 16px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100vh",
      background: theme.colors.grey1,
    },
    rightTitle: {
      ...theme.typography.txtBody1,
      fontWeight: "bold",
      color: theme.colors.clearMain,
      marginBottom: 20,
    },
    summaryAction: {
      width: "100%",
      marginTop: "20px"
    },
    buttonsBlock: {
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      backgroundColor: theme.colors.white,
      padding: "14px 61px 14px 14px",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    buttonsBlockOld: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",

      zIndex: 3,
      
      gap: 10,
      paddingRight: 10,
      height: 64,
      backgroundColor: theme.colors.white,
      
      borderRadius: 10,
      
      [theme.breakpoints.down("sm")]: {
        position: "fixed",
        bottom: 0,
        right: 0,
        paddingRight: 0,
        
        width: "100%",
        
        justifyContent: "center",
        
        zIndex: 999,
        borderRadius: 0,

        backgroundColor: theme.colors.white
      },
    },
    cancelButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      
      width: 140,
      height: 32,
      
      backgroundColor: theme.colors.white,
      
      borderRadius: 5,
      border: "1px solid #6462F3",
      
      ...theme.typography.buttons,
      color: theme.colors.clearMain,
      fontWeight: 700,
      
      [theme.breakpoints.down("sm")]: {
        width: 270
      },
    },
    continueButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      
      width: "auto",
      height: 32,
      
      backgroundColor: theme.colors.clearGreen,
      
      borderRadius: 5,
      
      ...theme.typography.buttons,
      color: theme.colors.grey1,
      fontWeight: 700,
      whiteSpace: "nowrap",
      
      "&:disabled": {
        opacity: 0.3,
        color: theme.colors.grey1,
      },
      "&:hover": {
        backgroundColor: theme.colors.clearGreen + "!important",
        color: theme.colors.grey1 + "!important",
      },
      [`& .MuiButton-label`]: {
        paddingLeft: 25,
        paddingRight: 25
      },
      
      [theme.breakpoints.down("sm")]: {
        width: 290
      },
    },
    goBackButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      
      width: 195,
      height: 32,
      
      backgroundColor: theme.colors.white,
      
      borderRadius: 51,
      
      ...theme.typography.buttons,
      color: "#ACB7C0",
      fontWeight: 700,
      
      "&:disabled": {
        opacity: 0.3,
        color: theme.colors.grey3,
      },
      "&:hover": {
        backgroundColor: theme.colors.white + "!important",
        color: theme.colors.grey4 + "!important",
      },
    }
  })
);

export default useStyles;
