import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { schedulerActions } from "../../../redux/scheduler-slice";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button, Tooltip } from "@material-ui/core";

import { DeleteIcon, PlusIcon } from "./../../../assets/icons";
import { AppointmentService } from "../../../api";
import { ServiceModel } from "../../../models/service";
import { ServiceUserModel } from "../../../models/service/service-user.model";
import useStyles from "./css";
import { ScheduleTime } from "../../../models/schedule";
import Autocomplete from "../../common/Autocomplete";
import ConfirmDeleteModal from "../../common/ConfirmDeleteModal";
import Typography from "@material-ui/core/Typography";
import BackToEstablishmentsButton from "../BackToEstablishmentsButton";
import { ServiceWithUsersModel } from "../../../models/service/service-with-users-model";

export default function Services() {
  const { t } = useTranslation(["general"]);
  const theme = useTheme();
  const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  const dispatch = useAppDispatch();
  
  const servicesWithUsers = useAppSelector((state) => state.scheduler.servicesWithUsers);
  const refreshAvailableHours = useAppSelector((state) => state.scheduler.refreshAvailableHours);
  const maxServiceIndex = useAppSelector((state) => state.scheduler.maxServiceIndex);
  const account = useAppSelector((state) => state.scheduler.account);
  
  const {
    setServiceUser,
    setSchedule,
    setIsValidSelection,
    setIsValidUser,
    setRefreshAvailableHours,
    setAvailableHours,
    setMaxServiceIndex,
    setServicesWithUsers,
    setScheduleTime,
    setShowLoader
  } = schedulerActions;
  
  const { accountBusinessName } = useParams<any>();
  const history = useHistory();
  
  const [servicesList, setServicesList] = useState<ServiceModel[]>([]);
  const [selectedServices, setSelectedServices] = useState<ServiceUserModel[]>([]);
  const [serviceSearch, setServiceSearch] = useState<ServiceModel[]>([]);
  const [search, setSearch] = useState<string>();
  
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<ServiceModel | null>(null);
  
  useEffect(() => {
    if (account.id) {
      loadServices();
    }
  }, [account.id]);
  
  useEffect(() => {
    if (search && search.length > 2) {
      (async () => {
        await loadSearchData();
      })();
    }
  }, [search]);
  
  async function loadServices() {
    dispatch(setShowLoader(true));
    const servicesResponse = await AppointmentService.getServices(account.id || "");
    dispatch(setShowLoader(false));
    const serviceIds = servicesResponse.map(service => service.id);
    let filteredServicesIds: string[] = [];
    
    serviceIds.forEach(s => {
      if (s !== null && s !== undefined) {
        filteredServicesIds.push(s);
      }
    })
    dispatch(setShowLoader(true));
    const servicesWithUsers = await AppointmentService.getUsersForServices(account.id, filteredServicesIds)
    dispatch(setShowLoader(false));
    dispatch(setServicesWithUsers(servicesWithUsers));
    
    setServicesList(servicesResponse);
  }
  
  async function loadSearchData() {
    dispatch(setShowLoader(true));
    const data = await AppointmentService.getServices(account.id!, search, ["Name", "Category"]);
    dispatch(setShowLoader(false));
    // avoid names duplicated
    const list: ServiceModel[] = data ?? [];
    const names = list.map((o) => o.name);
    const groupByName = list.filter(
      ({ name }, index) => !names.includes(name, index + 1)
    );
    
    setServiceSearch(groupByName || []);
  }
  
  function searchServicesOnSelected(service: ServiceModel) {
    if (service === undefined) {
      loadServices();
      return;
    }
    const serviceName = service.name;
    const filteredServices = servicesList.filter(service => service.name === serviceName);
    setServicesList(filteredServices);
  }
  
  function handleAddService(serviceId: string | null) {
    const services: ServiceUserModel[] = [...selectedServices];
    
    if (selectedServices.length && selectedServices[0].serviceId === null) {
      services.length = 0;
    }
    
    const newIndex = maxServiceIndex + 1;
    dispatch(setMaxServiceIndex(newIndex));
    
    
    let serviceWithUser = servicesWithUsers.find((x: ServiceWithUsersModel) => x.serviceId == serviceId);
    
    let newService = new ServiceUserModel({
      service: servicesList.find(x => x.id === serviceId) ?? null,
      serviceId: serviceId,
      userId: account.showAnySpecialistFromSite ? "All" : null,
      users: serviceWithUser?.users ?? [],
      index: maxServiceIndex
    });
    
    services.push(newService);
    
    setSelectedServices(services);
  }
  
  function handleAskToRemoveService(e: any, service: ServiceModel) {
    e.preventDefault();
    e.stopPropagation();

    setItemToDelete(service);
    setOpenDeleteDialog(true);
  }
  
  function handleDeleteService() {
    const services: ServiceUserModel[] = [...selectedServices];
    
    const indexToDelete = services.findIndex(service => service.serviceId === itemToDelete?.id);
    
    if (indexToDelete !== -1) {
      services.splice(indexToDelete, 1);
      
      const updatedServices = services.map((data, index) => ({
        ...data,
        index: index
      }));
      
      setSelectedServices(updatedServices);
      
      const newIndex = maxServiceIndex - 1;
      dispatch(setMaxServiceIndex(newIndex));
      
      setItemToDelete(null);
      setOpenDeleteDialog(false);
    }
    
  }
  
  const DivInfoDelete: React.FC = () => {
    return (<>
      <div className={classes.modalCenter} style={{ marginTop: 20 }}>
        <Typography className={classes.modalContentText}>
          {t("Are you sure you want to delete this service?")}
        </Typography>
      </div>
      <div className={classes.subtitle}>
        <hr style={{ width: 250 }}/>
        {itemToDelete && itemToDelete.name ? itemToDelete.name : "- -"}
      </div>
    </>);
  };
  
  function onScheduleAppointmentClick() {
    dispatch(setServiceUser([...selectedServices]));
    dispatch(setSchedule(undefined));
    dispatch(setIsValidSelection(true));
    dispatch(setIsValidUser(account.showAnySpecialistFromSite));
    dispatch(setScheduleTime(undefined));
    dispatch(setAvailableHours([new ScheduleTime()]));
    dispatch(setRefreshAvailableHours(refreshAvailableHours + 1));
    history.push(`/${accountBusinessName}/appointment`);
  }
  
  return (
    <div className={classes.servicesRoot}>
      {account.enterpriseBusinessNameUrl && <BackToEstablishmentsButton/>}
      <div className={classes.serviceFlexContainer} style={{ paddingBottom: !isMdMedia && servicesList.length < 2 ? 236 : 52}}>
        <div className={`${classes.searchContainer} ${!isMdMedia && account.enterpriseBusinessNameUrl && classes.marginTopZero}`}>
          <div className={classes.servicesText}>
            {t("Services")}
          </div>
          <Autocomplete
            style={{ width: "100%" }}
            items={serviceSearch}
            placeholder={t("Search for a service")}
            renderOption={(option: any) => `${option.name}`}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            onChange={(value: any, reason) => {
              if (reason === "input" && value.length >= 3)
                setSearch(value);
              else
                setServiceSearch([]);
            }}
            onSelected={(service: any) => {
              searchServicesOnSelected(service || undefined);
            }}
          />
        </div>
        
        <div className={classes.servicesContainer}>
          {servicesList.map((service, index) => (
            <div className={classes.serviceWindow} key={index} style={{ height: !isMdMedia && !service.photo ? 205 : 347}}>
              {service.photo ? (
                <img src={service.photo} className={classes.servicePhoto}/>
              ) : (
                <div className={classes.servicePhotoEmpty}></div>
              )}
              <div className={classes.descriptionContainer}>
                <div>
                  <div className={classes.serviceName}>
                    {`${service.categoryName} | ${service.name}`}
                  </div>
                  <div className={classes.servicePrice}>
                    {account.showPrices
                      ? `$ ${service.price} | ${service.duration} ${t("minutes")}`
                      : `${service.duration} ${t("minutes")}`
                    }
                  </div>
                </div>
                <Tooltip
                  title={service.description ?? ""}
                  classes={{ tooltip: classes.tooltipBlock }}
                >
                  <div className={classes.serviceDescription} style={{ height: !isMdMedia && !service.photo ? 67 : 56}}>
                    {service.description}
                  </div>
                </Tooltip>
                
                <div className={classes.buttonContainer}>
                  {!selectedServices.some(x => x.serviceId === service.id)
                    ? <Button
                      className={`${classes.addButton}`}
                      onClick={() => handleAddService(service.id)}
                      disabled={false}
                      style={{ width: 163 }}
                    >
                      <div>
                        <PlusIcon/> &nbsp; &nbsp;
                        {t("Add Service")}
                      </div>
                    </Button>
                    : <Button
                      className={`${classes.deleteButton}`}
                      onClick={(e) => handleAskToRemoveService(e, service)}
                      disabled={false}
                      style={{ width: 163 }}
                    >
                      <div>
                        <DeleteIcon/> &nbsp; &nbsp;
                        {t("Delete service")}
                      </div>
                    </Button>}
                
                </div>
              </div>
            
            </div>
          ))}
        
        </div>
      </div>
      {selectedServices.length > 0 &&
        <div className={classes.footerContainer}>
          <div className={classes.footerText}>
            {t("You have added")} {selectedServices.length} {t("Services").toLowerCase()}
          </div>
          <div className={classes.divider}/>
          <div className={classes.scheduleButtonContainer}>
            {account.showPrices &&
              <div className={classes.totalText}>
                <div>{t("Total")}:</div>
                <div>
                  ${selectedServices.reduce((total, x) => total + (x.service?.price || 0), 0)}
                </div>
              </div>}
            <Button
              className={`${classes.scheduleButton}`}
              onClick={() => onScheduleAppointmentClick()}
              disabled={false}
            >
              <div>
                {t("Schedule appointment")}
              </div>
            </Button>
          </div>
        </div>}
      <ConfirmDeleteModal
        textButtonConfirm={t("Delete service")}
        classModal={classes.modalDelete}
        open={openDeleteDialog}
        item={itemToDelete}
        componentInfo={<DivInfoDelete/>}
        onClose={() => setOpenDeleteDialog(false)}
        onDelete={handleDeleteService}
      />
    </div>
  );
};