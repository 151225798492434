import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    step2Root: {
      paddingRight: 10,
      marginTop: 18,
      maxWidth: 1084,
      marginBottom: 60,

      [theme.breakpoints.down("sm")]: {
        maxWidth: 272,
        marginTop: 8,
        padding: "0 0 123px 0"
      },
    },
    serviceContainer: {
      display: "flex",
      alignItems: "center",
      width: 1070,
      height: 73,
      borderRadius: 6,
      boxShadow: "2px 2px 12px 5px #ebebeb",
      marginBottom: 16,
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        width: 272,
        flexDirection: "column",
        backgroundColor: "#FFF",
        alignItems: "start",
        padding: 16,
        gap: 16
      },
    },
    imageContainer: {
      width: 122,
      marginRight: 38,
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    serviceInfoContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 5,
      marginRight: 28,
      [theme.breakpoints.down("sm")]: {
        width: "100%!important"
      },
    },
    serviceInfoHeader: {
      ...theme.typography.txtBody2,
      color: "#4A4D51",
      fontWeight: 500
    },
    serviceInfo: {
      ...theme.typography.txtBody2,
      color: "#6A6E72",
      fontWeight: 400,
      
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    servicePhoto: {
      width: "106%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 6,
    },
    deleteDiv: {
      paddingBottom: 7,
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      },
    },
    deleteButton: {
      "&:hover": {
        cursor: "pointer",
      },
      [theme.breakpoints.down("sm")]: {
        ...theme.typography.buttons,
        fontWeight: 700,
        
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        width: "100%",
        border: "1px solid #F15857",
        borderRadius: 5,
        color: "#F15857",
        gap: 8,
        padding: 10,
      },
    },
    modalCenter: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      gap: 15
    },
    modalContentText: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "17px",
      textAlign: "center",
      color: "#4A4D51",
    },
    modalContentTextServiceName: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "15px",
      lineHeight: "17px",
      textAlign: "center",
      color: "#4A4D51",
    },
    subtitle: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontSize: 14,
      textAlign: "center",
      color: theme.colors.grey5
    },
    footerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: 24,
      marginBottom: 75,

      [theme.breakpoints.down("sm")]: {
        position: "fixed",
        flexDirection: "column-reverse",
        width: "100%",
        bottom: 60,
        right: 0,
        marginBottom: 0,
        backgroundColor: theme.colors.white
      },
    },
    normalText: {
      ...theme.typography.txtBody1,
      fontFamily: 'Inter',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      color: "#5C6477"
    },
    cancellationContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      borderRadius: 6,
      boxShadow: "2px 2px 12px 5px #ebebeb",
      padding: "20px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        width: 272,
        marginLeft: "auto",
        marginRight: "auto",
        padding: 0,
        borderRadius: 0,
        boxShadow: "none"
      },
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
      gap: 3
    },
    icon: {
      [theme.breakpoints.down("sm")]: {
        margin: "-1px 3px 0 0"
      },
    },
    iconModal: {
      [theme.breakpoints.down("sm")]: {
        margin: "-5px 10px 0 0"
      },
    },
    cancellationTextContainer: {
      marginLeft: 2,
      [theme.breakpoints.down("sm")]: {
        marginLeft: -10
      },
    },
    separator: {
      height: 0,
      width: 272,
      borderTop: "1px solid #C9CDD3",
      margin: "0 0 0 10px",
    },
    totalServicesText: {
      marginBottom: 16,
      ...theme.typography.txtBody1,
      fontWeight: "bold",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    cancellationText: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey5
    },
    total: {
      display: "flex",
      justifyContent: "end",
      gap: 12,
      marginRight: 114,
      marginBottom: 15,
      minWidth: "10%",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "space-between",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: 10,
        width: 272,
        margin: 0
      },
    },
    totalSpan: {
      ...theme.typography.header4,
      [theme.breakpoints.down("sm")]: {
        ...theme.typography.txtBody1,
      },
      
    },
    
    totalValueSpan: {
      ...theme.typography.header4,
      fontWeight: 700,
      [theme.breakpoints.down("sm")]: {
        ...theme.typography.txtBody1,
        fontWeight: 700,
      },
    },
  })
);

export default useStyles;
