import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    step1Root: {
      width: 1039,
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        paddingBottom: 245
      },
    },
    step1Container: {
      [theme.breakpoints.down("sm")]: {},
    },
    stepTitle: {
      ...theme.typography.header2,
      fontWeight: "bold",
      color: theme.colors.clearMain,
    },
    stepCaption: {
      ...theme.typography.buttons,
      fontWeight: "bold",
      color: theme.colors.grey6,
    },
    selectService: {
      ...theme.typography.txtBody2,
      background: theme.colors.grey1,
      backgroundColor: theme.colors.grey1,
      border: `1px solid ${theme.colors.grey2}`,
      borderRadius: 5,
      height: 40,
      padding: "0px 9px 0px",
    },
    selectRoot: {
      color: theme.colors.grey5,
      "&:focus": {
        backgroundColor: theme.colors.grey1,
      },
      "&.MuiSelect-select": {
        "&:focus": {
          borderRadius: 5,
        },
      },
    },
    selected: {
      fontWeight: "bold",
      color: theme.colors.grey5,
    },
    placeholder: {
      color: "#676767",
      opacity: "0.42",
      "&.Mui-disabled": {
        opacity: "0.42",
      },
    },
    itemRoot: {
      ...theme.typography.txtBody1,
      color: theme.colors.grey5,
      height: 40,
      display: "flex",
      alignItems: "center",
    },
    itemSelected: {
      fontWeight: "bold",
      color: theme.colors.clearMain,
    },
    group: {
      ...theme.typography.txtBody2,
      fontWeight: "bold",
      color: theme.colors.grey4,
      height: 40,
      display: "flex",
      alignItems: "center",
      "&.Mui-disabled": {
        opacity: 1,
      },
    },
    addButton: {
      ...theme.typography.buttons,
      fontWeight: "bold",
      color: theme.colors.clearMain,
      width: 168,
      height: 32,
      borderRadius: 5,
      padding: "11px 16px",
      border: `1px solid ${theme.colors.clearMain}`,
      justifyContent: "space-around",
      "&.Mui-disabled": {
        color: theme.colors.clearMain,
      },
      [theme.breakpoints.down("sm")]: {
        width: 272
      },
    },
    addButtonContainer: {
      padding: "24px 0px 0px 0px",
      justifySelf: "center",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center"
      },
    },
    separatorWithoutLine: {
      marginBottom: 30,
    },
    noTimeSlotsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      alignItems: "center",
      marginBottom: 75,
      
      marginTop: 12,
      marginLeft: 54,

      width: 227,
      
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginBottom: 0
      },
    },
    noTimeSlotsTextBold: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey4,
      textAlign: "center",
      lineHeight: "17px",
      width: "80%",
      fontWeight: 700,
    },
    noTimeSlotsText: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey4,
      textAlign: "center",
      lineHeight: "17px",
      width: "80%",
    },
    separator: {
      height: 0,
      borderTop: `1px solid ${theme.colors.grey2}`,
      margin: "24px 0px 24px 26px",
    },
    deleteDiv: {
      position: "relative",
      top: -21,
      left: -30
    },
    deleteButton: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    modalDelete: {
      height: 260
    },
    modalCenter: {
      display: "flex",
      justifyContent: "center",
    },
    modalTitleSpace: {
      margin: "24px 0px 8px 0px",
    },
    subtitle: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 14,
      textAlign: "center",
      color: theme.colors.grey5
    },
    modalContentText: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "17px",
      textAlign: "center",
      color: "#4A4D51",
    },
    accordionSummary: {
      backgroundColor: "#f6f6f6",
      width: "100%"
    },
    accordionTitle: {
      fontFamily: 'Roboto',
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 12,
      color: theme.colors.grey5,
      position: "relative",
      top: 5,
      left: 10
    },
    inputWithLabel: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    controlLabel: {
      ...theme.typography.txtBody2,
      fontFamily: "Inter, sans-serif",
      fontWeight: "bold",
      display: "inline-flex",
      color: theme.colors.grey4,
      marginBottom: 8
    },
    calendarContainer: {
      marginTop: 27,
      marginLeft: -10,
      
      "& .MuiPickersBasePicker-container": {
        border: "none"
      },
      
      "& .MuiPickersStaticWrapper-staticWrapperRoot, & .MuiPickersBasePicker-pickerView": {
        minWidth: "auto"
      }
    },
    hoursCaption: {
      ...theme.typography.txtBody2,
      fontWeight: 700,
      color: theme.colors.grey6,
      marginTop: 8,
      "& p": {
        color: theme.colors.grey6,
      }
    },
    hoursContainer: {
      marginTop: 27,
      height: "105%",
      [theme.breakpoints.down("sm")]: {
        width: 272,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    dateNotSelected: {
      ...theme.typography.txtBody1,
      fontWeight: "bold",
      color: theme.colors.grey3,
    },
    schedulesHeader: {
      ...theme.typography.txtBody1,
      fontWeight: "bold",
      color: theme.colors.grey6,
    },

    ulSchedule: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 16,
      listStyle: "none",
      padding: 0,
      marginTop: 8,
      [theme.breakpoints.down("sm")]: {
        gap: "16px 24px"
      },
    },
    liSchedule: {
      userSelect: "none",
      pointerEvents: "all",
      
      minWidth: 135,
      height: 32,
      borderRadius: 5,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box",
      ...theme.typography.buttons,
      fontWeight: "bold",
      color: theme.colors.clearMain,
      border: `1px solid ${theme.colors.clearMain}`,
      "&:nth-child(3n + 3)": {
        marginRight: 0,
      },
      
      [theme.breakpoints.down("sm")]: {
        minWidth: 124,
      },
    },
    liScheduleEnabled: {
      "&:hover": {
        background: theme.colors.clearMain,
        color: theme.colors.grey1,
      },
      cursor: "pointer",
    },
    liScheduleDisabled: {
      opacity: 0.3,
      cursor: "default"
    },
    liScheduleActive: {
      background: theme.colors.clearMain,
      color: theme.colors.grey1,
    },
    servicesSimultaneouslyContainer: {
      marginTop: 15,
      ...theme.typography.txtBody1,
      fontWeight: "bold",
      color: theme.colors.clearMain,
    },
    dateText: {
      ...theme.typography.txtBody2,
      fontWeight: "bold",
      color: theme.colors.grey5,
      "&::placeholder": {
        fontWeight: "normal",
        color: "#676767",
      },
    },
    serviceUserContainer: {
      position: "relative",
      display: "flex",
      alignItems: "flex-end",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      },
    },
    serviceUserBox: {
      marginTop: 8,
      paddingBottom: 10,
      paddingRight: 10,
      [theme.breakpoints.down("sm")]: {
        paddingRight: 0
      },
    },
    schedulesContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 16
    }
  })
);

export default useStyles;
