import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    servicesRoot: {
      display: 'flex',
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "#FAFAFA"
      },
    },
    servicesContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 16,
      paddingBottom: 85,
    },
    serviceFlexContainer: {
      width: 1072,
      
      [theme.breakpoints.down("sm")]: {
        width: 272
      },
    },
    modalDelete: {
      height: "auto",
      maxHeight: 600,
      
      [theme.breakpoints.down("sm")]: {
        width: "97vw!important"
      },
    },
    modalCenter: {
      display: "flex",
      justifyContent: "center",
    },
    modalTitleSpace: {
      margin: "24px 0px 8px 0px",
    },
    subtitle: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 14,
      textAlign: "center",
      color: theme.colors.grey5
    },
    modalContentText: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "17px",
      textAlign: "center",
      color: "#4A4D51",
    },
    scheduleButtonContainer: {
      display: "flex",
      alignItems: "center",
      gap: 16,
      
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        width: 272,
        gap: 8
      },
    },
    servicesText: {
      ...theme.typography.header4,
      fontWeight: 700,
      color: theme.colors.grey6
    },
    totalText: {
      display: "flex",
      gap: 6,
      
      ...theme.typography.txtBody1,
      color: theme.colors.grey6,
      
      [theme.breakpoints.down("sm")]: {

        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%"
      },
    },
    footerText: {
      ...theme.typography.txtBody1,
      fontWeight: 700,
      color: theme.colors.grey6,
      
      [theme.breakpoints.down("sm")]: {
        ...theme.typography.txtBody2,
        fontWeight: 700,
        width: 272
      },
    },
    divider: {
      height: 1,
      width: 272,
      borderTop: "1px solid #ACB7C0",
      margin: "9px 0 7px 0",
      
      [theme.breakpoints.up("md")]: {
        display: "none"
      },
    },
    searchContainer: {
      display: "flex",
      gap: 25,
      alignItems: "center",
      marginTop: 30,
      marginBottom: 30,
      
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 16
      },
    },
    footerContainer: {
      position: 'fixed',
      padding: "0 40px 0 40px",
      height: 64,
      bottom: 0,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#FAFAFA",
      
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        height: "auto",
        backgroundColor: "#FFFFFF",
        padding: "16px 0 16px 0"
      },
    },
    serviceWindow: {
      width: 256,
      
      border: '1px solid #ccc',
      borderRadius: 10,
      
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      },
    },
    servicePrice: {
      fontSize: 14,
      fontFamily: "Roboto",
    },
    marginTopZero: {
      marginTop: 0
    },
    tooltipBlock: {
      backgroundColor: theme.colors.grey1,
      ...theme.typography.txtBody2,
      color: theme.colors.grey5,
    },
    buttonContainer: {
      textAlign: "right"
    },
    serviceDescription: {
      overflow: "hidden",
      wordWrap: "break-word",
      ...theme.typography.txtBody2,
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 4,
      height: 67
    },
    descriptionContainer: {
      padding: 16,
      display: "flex",
      flexDirection: "column",
      gap: 16
    },
    serviceName: {
      overflow: "hidden",
      fontSize: 14,
      fontWeight: 700,
      fontFamily: "Inter",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 1
    },
    servicePhoto: {
      width: '100%',
      height: "42%",
      objectFit: "cover",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      [theme.breakpoints.down("sm")]: {
        height: "44%",
      },
    },
    servicePhotoEmpty: {
      height: "42%",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      backgroundColor: "#FAFAFA",
      
      [theme.breakpoints.down("sm")]: {
        display: "none"
      },
    },
    addButton: {
      ...theme.typography.buttons,
      fontWeight: "bold",
      color: theme.colors.clearMain,
      width: 168,
      height: 32,
      borderRadius: 5,
      padding: "11px 16px",
      border: `1px solid ${theme.colors.clearMain}`,
      justifyContent: "space-around",
      marginTop: 10,
      "&.Mui-disabled": {
        color: theme.colors.clearMain,
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 0
      },
    },
    scheduleButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      
      width: 163,
      height: 32,
      
      backgroundColor: theme.colors.clearGreen,
      
      borderRadius: 5,
      
      ...theme.typography.buttons,
      color: theme.colors.grey1,
      fontWeight: 700,
      
      whiteSpace: "nowrap",
      
      "&:disabled": {
        opacity: 0.3,
        color: theme.colors.grey1,
      },
      "&:hover": {
        backgroundColor: theme.colors.clearGreen + "!important",
        color: theme.colors.grey1 + "!important",
      },
      [`& .MuiButton-label`]: {
        paddingLeft: 25,
        paddingRight: 25
      },
      
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      },
    },
    deleteButton: {
      ...theme.typography.buttons,
      fontWeight: "bold",
      color: theme.colors.clearRed,
      width: 168,
      height: 32,
      borderRadius: 5,
      padding: "11px 16px",
      border: `1px solid ${theme.colors.clearRed}`,
      justifyContent: "space-around",
      "&.Mui-disabled": {
        color: theme.colors.clearRed,
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },
    
  })
);

export default useStyles;
