import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@material-ui/core/Button";

import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { schedulerActions } from "../../../redux/scheduler-slice";

import useStyles from "./css";
import AppointmentScheduleStepper from "../AppointmentScheduleStepper";
import ScheduleAppointmentStepOne from "../ScheduleAppointmentStepOne";
import ScheduleAppointmentStepTwo from "../ScheduleAppointmentStepTwo";
import ScheduleAppointmentStepThree from "../ScheduleAppointmentStepThree";
import ScheduleAppointmentSuccessfullyStep from "../ScheduleAppointmentSuccessfullyStep";
import { ScheduleAppointment } from "../../../models/schedule";
import { AppointmentService } from "../../../api";
import AppointmentScheduleBusyModal from "../AppointmentScheduleBusyModal";
import CancelAppointmentButton from "../CancelAppointmentButton";
import BackToEstablishmentsButton from "../../account/BackToEstablishmentsButton";
import ScheduleAppointmentFailedStep from "../ScheduleAppointmentFailedStep";


export default function AppointmentScheduler() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["general"]);
  const account = useAppSelector((state) => state.scheduler.account);
  const appointment = useAppSelector((state) => state.scheduler.scheduleAppointment);
  
  const scheduleTime = useAppSelector((state) => state.scheduler.scheduleTime);
  const scheduledSimultaneousServices = useAppSelector((state) => state.scheduler.isServicesPerformedSimultaneously);
  const schedule = useAppSelector((state) => state.scheduler.schedule);
  
  const isUpdate = useAppSelector((state) => state.scheduler.isUpdate);
  const isModified = useAppSelector((state) => state.scheduler.isModified);
  const isEditable = useAppSelector((state) => state.scheduler.isEditable);
  const showLoader = useAppSelector((state) => state.scheduler.showLoader);
  const refreshAvailableHours = useAppSelector((state) => state.scheduler.refreshAvailableHours);
  const serviceUserModels = useAppSelector((state) => state.scheduler.serviceUser);
  const currentDate = useAppSelector((state) => state.scheduler.currentDate);
  const openScheduleBusyModal = useAppSelector((state) => state.scheduler.isOpenScheduleBusyModal);
  
  const customer = useAppSelector((state) => state.scheduler.scheduleAppointment);
  const customerValid = useAppSelector((state) => state.scheduler.customerValid);
  
  const [activeStep, setActiveStep] = useState<0 | 1 | 2 | 3>(0);
  const [isCustomerBlocked, setIsCustomerBlocked] = useState<boolean>(false);
  
  const {
    setScheduleAppointment,
    setIsOpenCustomerModal,
    setIsOpenScheduleBusyModal,
    setRefreshAvailableHours,
    setShowLoader
  } = schedulerActions;
  
  function _setScheduleAppointment(value: ScheduleAppointment) {
    dispatch(setScheduleAppointment(value));
  }
  
  function handleGoBackButtonClick() {
    setActiveStep((prev) => prev - 1 as 0 | 1 | 2 | 3);
    window.scrollTo(0, 0);
  }
  
  async function handleContinueButtonClick() {
    if (activeStep === 2) {
      await handleFormFinish();
    } else {
      setActiveStep((prev) => prev + 1 as 0 | 1 | 2);
      window.scrollTo(0, 0)
    }
  }
  
  async function handleFormFinish() {
    if (schedule && scheduleTime && scheduleTime.userId) {
      const startDate = new Date(currentDate);
      const startTime = schedule.split("-")[0];
      startDate.setHours(
        +startTime.split(":")[0],
        +startTime.split(":")[1],
        0,
        0
      );
      
      if (!serviceUserModels) return;
      
      const scheduleAppointment = new ScheduleAppointment({
        ...customer,
        accountId: account.id,
        services: serviceUserModels,
        startDate: startDate,
        scheduledSimultaneousServices: scheduledSimultaneousServices
      });
      
      _setScheduleAppointment(scheduleAppointment);
      
      dispatch(setShowLoader(true));
      
      const updateResponse = await (isUpdate
        ? AppointmentService.updateAppointment(scheduleAppointment)
        : AppointmentService.createAppointment(scheduleAppointment));
      
      if (updateResponse.isScheduleBusy) {
        dispatch(setIsOpenCustomerModal(false));
        dispatch(setIsOpenScheduleBusyModal(true));
        dispatch(setRefreshAvailableHours(refreshAvailableHours + 1));
      } else if (updateResponse.isCustomerBlocked) {
        setIsCustomerBlocked(true);
        setActiveStep(3);
      } else {
        setActiveStep(3);
      }
      
      dispatch(setShowLoader(false));
    }
  }
  
  function stepOneIsReady(): boolean {
    return schedule != undefined;
  }
  
  function stepTwoIsReady(): boolean {
    return scheduleTime != undefined;
  }
  
  function stepThreeIsReady(): boolean {
    return (customerValid?.name !== undefined && customerValid.name)
      && (customerValid?.lastName !== undefined && customerValid.lastName)
      && (customerValid?.mobile !== undefined && customerValid.mobile)
      && !showLoader
  }
  
  
  function isContinueButtonDisabled(): boolean {
    if (!isEditable) {
      return true;
    }
    
    if (isUpdate && !isModified && activeStep === 0) {
      return true;
    }
    
    switch (activeStep) {
      case 0:
        return !(stepOneIsReady());
      case 1:
        return !(stepTwoIsReady());
      case 2:
        return !stepThreeIsReady();
      default:
        return false;
    }
  }
  
  function isFormReadyToFinalize(): boolean {
    return activeStep === 2;
  }
  
  function getModifyCaptionWidth(activeStep: number) {
    switch (activeStep) {
      case 0:
        return 895;
      case 1:
        return 1070;
      case 2:
        return 804;
    }
  }
  
  function getButtonText() {
    if (isFormReadyToFinalize()) {
      return isUpdate ? t("Modify appointment") : t("Schedule appointment");
    } else {
      return t("Continue");
    }
  }
  
  const ButtonsBlock = () =>
    <>
      {activeStep !== 3 && <div className={classes.buttonsBlock}>
        <div className={classes.buttonsFlexContainer}>
          {activeStep !== 0 &&
            <Button
              className={classes.goBackButton}
              onClick={handleGoBackButtonClick}
            >
              {t("Go back")}
            </Button>
          }
          {activeStep === 0 && account.allowCustomerToCancelFromSite && isUpdate &&
            <CancelAppointmentButton
              classes={classes}/>
          }
          <Button
            className={classes.continueButton}
            disabled={isContinueButtonDisabled()}
            onMouseUp={handleContinueButtonClick}
          >
            {getButtonText()}
          </Button>
        </div>
      </div>}
    </>
  
  const FirstStepContent = () =>
    <>
      <ScheduleAppointmentStepOne/>
      <ButtonsBlock/>
    </>
  
  const SecondStepContent = () =>
    <>
      <ScheduleAppointmentStepTwo/>
      <ButtonsBlock/>
    </>
  
  const ThirdStepContent = () =>
    <>
      <ScheduleAppointmentStepThree/>
      <ButtonsBlock/>
    </>
  
  const FormFinishedContent = () =>
    <>
      {!isCustomerBlocked &&
        <ScheduleAppointmentSuccessfullyStep/>
      }
      {isCustomerBlocked &&
        <ScheduleAppointmentFailedStep/>
      }
    </>
  
  return (
    <div className={classes.root}>
      {!isUpdate && account.enterpriseBusinessNameUrl && <BackToEstablishmentsButton/>}
      {activeStep !== 3 && <AppointmentScheduleStepper
        activeStep={activeStep}
      />}
      {isUpdate && activeStep !== 3 && (
        <div className={classes.modifyCaptionContainer}>
          <div className={classes.modifyCaption} style={{ width: getModifyCaptionWidth(activeStep) }}>
            {t("MODIFY YOUR APPOINTMENT")}
          </div>
        </div>
      )}
      <div className={classes.content}>
        <>
          {activeStep === 0 && FirstStepContent()}
          {activeStep === 1 && SecondStepContent()}
          {activeStep === 2 && ThirdStepContent()}
          {activeStep === 3 && FormFinishedContent()}
        </>
      </div>
      <AppointmentScheduleBusyModal
        open={openScheduleBusyModal}
        appointment={appointment}
        onClose={() => dispatch(setIsOpenScheduleBusyModal(false))}
      />
    </div>
  );
};
