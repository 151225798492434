import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { schedulerActions } from "../../../redux/scheduler-slice";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { TextareaAutosize } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";

import useStyles from "./css";
import { ScheduleAppointment } from "../../../models/schedule";
import InputMobileCode from "../../common/InputMobileCode";
import { CustomerValidModel } from "../../../models/customer";
import { Regex } from "../../../constants";
import { AppointmentService } from "../../../api";
import Country from "../../../models/common/country";


export default function ScheduleAppointmentStepThree() {

  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  const theme = useTheme();
  const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useAppDispatch();
  const { setScheduleAppointment, setCustomerValid } = schedulerActions;

  const location = useLocation();

  const [nameInputNotValid, setNameInputNotValid] = useState<boolean>(false);
  const [lastNameInputNotValid, setLastNameInputNotValid] = useState<boolean>(false);
  
  const customer = useAppSelector((state) => state.scheduler.scheduleAppointment);
  const isUpdate = useAppSelector((state) => state.scheduler.isUpdate);
  const customerValid = useAppSelector((state) => state.scheduler.customerValid);
  
  function _setScheduleAppointment(value: ScheduleAppointment) {
    dispatch(setScheduleAppointment(value));
  }
  
  function _setCustomerValid(value: CustomerValidModel) {
    dispatch(setCustomerValid(value));
  }
  
  
  function handleNameChange(event: React.ChangeEvent<{ value: string }>) {
    _setCustomerValid(
      new CustomerValidModel({
        ...customerValid,
        name: Regex.MultipleSpacesName.test(event.target.value),
      })
    );
    setNameInputNotValid(!Regex.MultipleSpacesName.test(event.target.value));
    _setScheduleAppointment(
      new ScheduleAppointment({ ...customer, firstName: event.target.value })
    );
  }
  
  function handleLastNameChange(event: React.ChangeEvent<{ value: string }>) {
    _setCustomerValid(
      new CustomerValidModel({
        ...customerValid,
        lastName: Regex.MultipleSpacesName.test(event.target.value),
      })
    );
    setLastNameInputNotValid(!Regex.MultipleSpacesName.test(event.target.value));
    _setScheduleAppointment(
      new ScheduleAppointment({ ...customer, lastName: event.target.value })
    );
  }
  
  function handleNotesChange(event: React.ChangeEvent<{ value: string }>) {
    _setScheduleAppointment(new ScheduleAppointment({ ...customer, notes: event.target.value, })
    );
  }
  
  useEffect(() => {
    if (!isUpdate) {
      const urlSearchParams = new URLSearchParams(location.search);
      const preselectedCustomerId = urlSearchParams.get('customerId');
      if (preselectedCustomerId) {
        AppointmentService.getProtectedCustomerInfo(preselectedCustomerId)
          .then(customerInfo => {
            if (customerInfo.name){
              _setScheduleAppointment(
                new ScheduleAppointment({
                  ...customer,
                  firstName: customerInfo.name,
                  lastName: customerInfo.lastName,
                  mobile: customerInfo.mobile.body,
                  mobileCodeIso3: customerInfo.mobile.countryIso,
                  notes: "",
                })
              );
              _setCustomerValid(
                new CustomerValidModel({
                  ...customerValid,
                  name: true,
                  lastName: true,
                  mobile: true
                })
              );
            }
          });
      } else {
        _setScheduleAppointment(
          new ScheduleAppointment({
            ...customer,
            mobileCodeIso3: customer.mobileCodeIso3,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  function handleMobileChange(num: any, c: Country) {
    _setScheduleAppointment(
      new ScheduleAppointment({
        ...customer,
        mobile: num.replace(/[^0-9]/g, ""),
        mobileCodeIso3: c.iso3,
        mobileCode: c.dialCode,
        mobileDigits: c.dialDigits,
        mobileTemplate: c.dialTemplate
      })
    );
  }
  
  function handleMobileCodeChange(c: Country) {
    let customerMobile = customer.mobile;
    if(customerMobile.length > c.dialDigits) {
      customerMobile = customerMobile.slice(0, c.dialDigits);
    }
    
    _setScheduleAppointment(
      new ScheduleAppointment({
        ...customer,
        mobileCodeIso3: c.iso3,
        mobileCode: c.dialCode,
        mobileDigits: c.dialDigits,
        mobileTemplate: c.dialTemplate,
        mobile: customerMobile
      }));
  }
  
  function handleValidMobile(valid: boolean) {
    setCustomerValid({ ...customerValid, mobile: valid });
    _setCustomerValid(new CustomerValidModel({ ...customerValid, mobile: valid, })
    );
  }
  
  function onNameInputBlur(e: any) {
    if (customer
      && customer.firstName
      && customer.firstName.length > 0
      && Regex.MultipleSpacesName.test(customer.firstName)
      && !/\d/.test(customer.firstName)) {
      _setCustomerValid(new CustomerValidModel({ ...customerValid, name: true, }));
      setNameInputNotValid(false);
    } else {
      _setCustomerValid(new CustomerValidModel({ ...customerValid, name: false, }));
      setNameInputNotValid(true);
    }
  }
  
  function onLastNameInputBlur() {
    if (customer
      && customer.lastName
      && customer.lastName.length > 0
      && Regex.MultipleSpacesName.test(customer.lastName)
      && !/\d/.test(customer.lastName)) {
      setLastNameInputNotValid(false);
      _setCustomerValid(new CustomerValidModel({ ...customerValid, lastName: true, }));
    } else {
      setLastNameInputNotValid(true);
      _setCustomerValid(new CustomerValidModel({ ...customerValid, lastName: false, }));
    }
  }
  
  return (
    <div className={classes.step3Root}>
      <div className={classes.topInputsContainer}>
        <div className={classes.topInputsContainerItem}>
          <label htmlFor="customer-name" className={classes.label}>
            {t("Name(s)")} <span style={{ color: "#6462F3" }}>*</span>
          </label>
          <InputBase
            id="customer-name"
            className={`${classes.input} ${
              nameInputNotValid
                ? classes.inputError
                : ""
            }`}
            classes={{
              focused: classes.inputFocused,
              disabled: classes.inputDisabled,
              input: classes.inputText,
            }}
            error={nameInputNotValid}
            onBlur={(e) => onNameInputBlur(e)}
            placeholder={t("Enter your name")}
            value={customer.firstName || ""}
            inputProps={{
              maxLength: 100
            }}
            onChange={handleNameChange}
          />
        </div>
        <div className={classes.topInputsContainerItem}>
          <label htmlFor="customer-last-name" className={classes.label}>
            {t("Last name(s)")} <span style={{ color: "#6462F3" }}>*</span>
          </label>
          <InputBase
            id="customer-last-name"
            className={`${classes.input} ${
              lastNameInputNotValid
                ? classes.inputError
                : ""
            }`}
            classes={{
              focused: classes.inputFocused,
              disabled: classes.inputDisabled,
              input: classes.inputText,
            }}
            error={lastNameInputNotValid}
            onBlur={onLastNameInputBlur}
            placeholder={t("Enter your last name")}
            value={customer.lastName || ""}
            inputProps={{
              maxLength: 100
            }}
            onChange={handleLastNameChange}
          />
        </div>
        <div className={classes.topInputsContainerItem}>
          <div style={{ zIndex: 1001 }}>
            <InputMobileCode
              label={t("WhatsApp")}
              isOptional={false}
              widthSelect={"100%"}
              widthList={isMdMedia ? 260 : "100%"}
              isValid={handleValidMobile}
              onBlur={handleValidMobile}
              onChangeItem={handleMobileCodeChange}
              onChangeMobile={handleMobileChange}
              codeIso3={customer.mobileCodeIso3!}
              dialDigits={customer.mobileDigits!}
              mobile={customer.mobile}/>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 18 }}>
        <label htmlFor="customer-notes" className={classes.label}>
          {t("Comments")} <span>({t("Optional")})</span>
        </label>
        <TextareaAutosize
          id="customer-notes"
          className={`${classes.inputTextareaAutosize} MuiInputBase-input MuiInput-input`}
          placeholder={t("Write any comment you want to share with us")}
          value={customer.notes || ""}
          maxLength={5000}
          onChange={handleNotesChange}
        />
      </div>
    </div>
  );
};
