import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

import { ExclamationModalProps } from "./props";
import useStyles from "./css";

export default function InformationModal(props: ExclamationModalProps) {

    const { t } = useTranslation(["general"]);
    const { open, title, body, okTextButton, height, onClose, scrollable } = props;
    const classes = useStyles(scrollable ?? false)();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>

            <Fade in={open}>
                <div className={classes.modalPaper} style={{height: height || "auto"}}>
                    <div className={classes.modalContent}>

                        <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`} >
                            <Typography className={classes.modalTitleText}>
                                {title}
                            </Typography>
                        </div>
                        <div>
                            {body}
                        </div>

                        <div className={classes.modalActions}>
                            <Button className={`${classes.button} ${classes.btnOk}`} onClick={onClose} >
                                {okTextButton ? okTextButton : t("Ok")}
                            </Button>
                        </div>

                    </div>
                </div>
            </Fade>
        </Modal>
    );
}