export const Url = {
  UATHost: "https://uatsitiodereservas.somosclear.com",
  NotFound: "https://somosclear.com/",
  Appointments: {
    Main: "/:accountBusinessName/appointment",
  },
  Navbar: {
    AboutUs: "/:accountBusinessName/about",
    Services: "/:accountBusinessName/services",
    ScheduleAppointment: "/:accountBusinessName/appointment",
  },
  CanceledConfirmation: {
    Main: "/:accountBusinessName/canceled-confirmation",
  },
  PatientForm: "/:accountBusinessName/patient-form",
  PrivacyNotice: "/:accountBusinessName/privacy-notice",
  InformedConsentLetter: "/:accountBusinessName/informed-consent-letter"
};
