import React from "react";
import { HelmetProvider } from 'react-helmet-async';

import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { Url } from "./constants";
import AppointmentRouter from "./pages/appointments/AppointmentRouter";
import CancelConfirmationRouter from "./pages/confirmation/CancelConfirmationRouter";
import PatientFormPage from "./components/patientform/PatientFormPage";
import PrivacyNoticePage from "./components/patientform/PrivacyNoticePage";
import InformedConsentLetterPage from "./components/patientform/InformedConsentLetterPage";
import { useAlert } from "./components/common/notifications/AlertProvider";

import "./custom.css";
import Layout from "./components/layout/Layout";
import AccountInfo from "./components/account/AccountInfo";
import Services from "./components/account/Services";


export default function () {
  
  const location = useLocation();
  
  const tryRedirectToUat = () => {
    if (!window.location.hostname.toLowerCase().startsWith("uat") && location.pathname.toLowerCase().startsWith("/uat")) {
      window.location.href = Url.UATHost + location.pathname + location.search;
      return true;
    }
    return false;
  }
  
  useAlert();
  
  return (
    <>
      <HelmetProvider>
        {tryRedirectToUat() ? <></> :
          <Switch>
            {/* removing trailing slash first */}
            <Redirect
              from='/:url*(/+)'
              to={window.location.pathname.slice(0, -1)}
            />
            <Route exact path={"/:accountBusinessName/confirmation"} component={CancelConfirmationRouter}/>
            <Route exact path={"/:accountBusinessName"} component={() => (
              <Layout>
                <AppointmentRouter/>
              </Layout>
            )}/>
            <Route path={Url.Navbar.ScheduleAppointment} component={() => (
              <Layout>
                <AppointmentRouter/>
              </Layout>
            )}/>
            <Route path={Url.Navbar.AboutUs} component={() => (
              <Layout>
                <AccountInfo/>
            </Layout>
          )}/>
          <Route path={Url.Navbar.Services} component={() => (
            <Layout>
              <Services/>
              </Layout>
            )}/>
            <Route path={Url.CanceledConfirmation.Main} component={() => (
            <Layout>
              <CancelConfirmationRouter/>
            </Layout>
          )}/>
            
            <Route path={Url.PatientForm} component={PatientFormPage}/>
            <Route path={Url.PrivacyNotice} component={PrivacyNoticePage}/>
            <Route path={Url.InformedConsentLetter} component={InformedConsentLetterPage}/>
            
            <Route path="/" component={() => {
              window.location.href = Url.NotFound;
              return null;
            }}/>
          </Switch>
        }
      </HelmetProvider>
    </>
  );
}